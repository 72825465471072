import { createAsyncThunk } from "@reduxjs/toolkit";
import { sendRequestJSON } from "src/lib/axios";
import { toastMessage } from "../commonSlice";
import { TimesheetAPIService } from "src/constants";
import { AuthState } from "src/types/auth/auth";
import { logout } from "../auth/thunks";
import {
  IApproveRejectTimesheetPayload,
  IFetchPmTimesheetListPayload,
  ITimesheetSlice,
  IFetchAllEmpTimesheet
} from "./types";

export const fetchTimesheetList = createAsyncThunk(
  "timesheet/fetchTimesheetList",
  async (payload: any, { dispatch, getState }) => {
    const state: AuthState = (getState() as any).loginInformation;
    let url = `${TimesheetAPIService.GET_TIMESHEET_DATA}/?userId=${state.user.employeeInfo.employeeId}`;

    if (payload?.status) {
      url += `&status=${payload.status}`;
    }
    if (payload?.startDate) {
      url += `&startDate=${payload.startDate}&endDate=${payload.endDate}`;
    }
    try {
      const response = await sendRequestJSON(null, url, "get");
      if (response?.error) {
        dispatch(
          toastMessage({
            error: true,
            message: response.result.message,
          })
        );
      }
      return response.result.data?.data;
    } catch (error) {
      if (error.response.data === "jwt expired") {
        dispatch(logout());
        return null;
      }
      dispatch(
        toastMessage({
          error: true,
          message: error.response.data,
        })
      );
      return null;
    }
  }
);

export const fetchPmTimesheetList = createAsyncThunk(
  "timesheet/fetchPmTimesheetList",
  async (payload: IFetchPmTimesheetListPayload, { dispatch, getState }) => {
    const state: AuthState = (getState() as any).loginInformation;
    try {
      const response = await sendRequestJSON(
        {
          pmId: state.user.employeeInfo.employeeId,
          startDate: payload.firstDay, // "2023-10-02",
          endDate: payload.lastDay, //"2023-10-08",

          projectId: payload.projectId,
        },
        TimesheetAPIService.GET_PM_TIMESHEET,
        "post"
      );
      if (response?.error) {
        dispatch(
          toastMessage({
            error: true,
            message: response.result.message,
          })
        );
      }
      return response.result.data?.data;
    } catch (error) {
      if (error.response.data === "jwt expired") {
        dispatch(logout());
        return [];
      }
      dispatch(
        toastMessage({
          error: true,
          message: error.response.data,
        })
      );
      return [];
    }
  }
);

export const fetchAllEmpTimesheet = createAsyncThunk(
  "timesheet/get-all-emp-timesheet",
  async(obj:IFetchAllEmpTimesheet, {dispatch, getState}) => {
    try {
      const response = await sendRequestJSON(
        null,
        `${TimesheetAPIService.GET_ALL_EMP_TIMESHEET}?startDate=${obj.startDate}&endDate=${obj.endDate}`,
        "get"
      )

      if (response?.error) {
        dispatch(
          toastMessage({
            error: true,
            message: response.result.message,
          })
        );
      }
      return response.result.data?.data;
    } catch (error) {
      if (error.response.data === "jwt expired") {
        dispatch(logout());
        return [];
      }
      dispatch(
        toastMessage({
          error: true,
          message: error.response.data,
        })
      );
      return [];
    }
  }
)

export const fetchAllPmProjects = createAsyncThunk(
  "timesheet/fetchAllPmProjects",
  async (_, { dispatch, getState }) => {
    try {
      const state: AuthState = (getState() as any).loginInformation;
      const response = await sendRequestJSON(
        null,
        `${TimesheetAPIService.GET_PM_PROJECTS}?empId=${state.user.employeeInfo.employeeId}`,
        "get"
      );
      if (response?.error) {
        dispatch(
          toastMessage({
            error: true,
            message: response.result.message,
          })
        );
      }
      return response.result.data?.data;
    } catch (error) {
      if (error.response.data === "jwt expired") {
        dispatch(logout());
        return null;
      }
      dispatch(
        toastMessage({
          error: true,
          message: error.response.data,
        })
      );
      return null;
    }
  }
);

export const fetchAllProjects = createAsyncThunk(
  "timesheet/fetchAllProjects",
  async (_, { dispatch, getState }) => {
    try {
      const state: AuthState = (getState() as any).loginInformation;
      const response = await sendRequestJSON(
        null,
        `${TimesheetAPIService.GET_PROJECTS}?empId=${state.user.employeeInfo.employeeId}`,
        "get"
      );
      if (response?.error) {
        dispatch(
          toastMessage({
            error: true,
            message: response.result.message,
          })
        );
      }
      return response.result.data?.data;
    } catch (error) {
      if (error.response.data === "jwt expired") {
        dispatch(logout());
        return null;
      }
      dispatch(
        toastMessage({
          error: true,
          message: error.response.data,
        })
      );
      return null;
    }
  }
);

export const fetchAllTasks = createAsyncThunk(
  "timesheet/fetchAllTasks",
  async (_, { dispatch }) => {
    try {
      const response = await sendRequestJSON(
        null,
        TimesheetAPIService.GET_TASKS,
        "get"
      );
      if (response?.error) {
        dispatch(
          toastMessage({
            error: true,
            message: response.result.message,
          })
        );
      }
      return response.result.data?.data;
    } catch (error) {
      if (error.response.data === "jwt expired") {
        dispatch(logout());
        return null;
      }
      dispatch(
        toastMessage({
          error: true,
          message: error.response.data,
        })
      );
      return null;
    }
  }
);

export const approveRejectTimesheet = createAsyncThunk(
  "timesheet/approveRejectTimesheet",
  async (payload: IApproveRejectTimesheetPayload, { dispatch, getState }) => {
    const state: AuthState = (getState() as any).loginInformation;
    try {
      const response = await sendRequestJSON(
        {
          employeeId: state.user.employeeInfo.employeeId,
          timeSheetId: payload.timeSheetId,
          employeeProjectId: payload.employeeProjectId,
          taskId: payload.taskId,
          decision: payload.decision,
          comment: payload?.comment ?? "",
        },
        TimesheetAPIService.APPROVE_REJECT_TIMESHEET,
        "put"
      );
      // if (response.result.data?.data) {
      //   dispatch(
      //     toastMessage({
      //       message: "Timesheet updated successfully",
      //     })
      //   );
      // } else {
      //   dispatch(
      //     toastMessage({
      //       error: true,
      //       message: "Unable to update the timesheet",
      //     })
      //   );
      // }
      if (response?.error) {
        dispatch(
          toastMessage({
            error: true,
            message: response.result.message,
          })
        );
      } else {
        dispatch(
          toastMessage({
            message: response.result.data?.message,
          })
        );
      }
      return response.result.data?.data;
    } catch (error) {
      if (error.response.data === "jwt expired") {
        dispatch(logout());
        return [];
      }
      dispatch(
        toastMessage({
          error: true,
          message: error.response.data,
        })
      );
      return [];
    }
  }
);

export const saveTimesheet = createAsyncThunk(
  "timesheet/saveTimesheet",
  async (_, { dispatch, getState }) => {
    const loginState: AuthState = (getState() as any).loginInformation;
    const state: ITimesheetSlice = (getState() as any).timesheet;
    try {
      if (!state.timesheetList[0].childTimeSheets) return;

      if (
        state.timesheetList[0].childTimeSheets[0][0].childTimeSheetId === -1
      ) {
        if (!state.timesheetList[0].childTimeSheets[0][0].projectCode) {
          dispatch(
            toastMessage({
              error: true,
              message: "Project not selected",
            })
          );
          return;
        }
        if (!state.timesheetList[0].childTimeSheets[0][0].taskId) {
          dispatch(
            toastMessage({
              error: true,
              message: "Task not selected",
            })
          );
          return;
        }

        const filteredCS = state.timesheetList[0].childTimeSheets.filter(
          (childsheet) =>
            childsheet[0].projectCode ===
              state.timesheetList[0].childTimeSheets[0][0].projectCode &&
            childsheet[0].taskId ===
              state.timesheetList[0].childTimeSheets[0][0].taskId &&
            childsheet[0].childTimeSheetId !== -1
        );
        if (filteredCS.length > 0) {
          dispatch(
            toastMessage({
              error: true,
              message: "Duplicate project and task",
            })
          );
          return;
        }

        const project = state.allProjects.filter(
          (project) =>
            project.projectCode ===
            state.timesheetList[0].childTimeSheets[0][0].projectCode
        )[0];
        const childTimeSheet = state.timesheetList[0].childTimeSheets[0].map(
          (childsheet) => ({
            employeeProjectId: childsheet.employeeProjectId,
            employeeId: loginState.user.employeeInfo.employeeId,
            projectId: childsheet.employeeProjectId,
            taskId: childsheet.taskId,
            date: childsheet.date,
            hours: childsheet.hours,
            pmId: project.managerId,
          })
        );
        const response = await sendRequestJSON(
          {
            timeSheetId: state.timesheetList[0].timeSheetId,
            childTimeSheet: childTimeSheet,
          },
          TimesheetAPIService.SAVE_TIMESHEET,
          "post"
        );
        if (response?.error) {
          dispatch(
            toastMessage({
              error: true,
              message: response.result.message,
            })
          );
        } else {
          dispatch(
            toastMessage({
              message: response.result.data?.message,
            })
          );
        }
        return response.result.data?.data;
      }
    } catch (error) {
      if (error.response.data === "jwt expired") {
        dispatch(logout());
        return null;
      }
      dispatch(
        toastMessage({
          error: true,
          message: error.response.data,
        })
      );
      return null;
    }
  }
);

export const editTimesheet = createAsyncThunk(
  "timesheet/editTimesheet",
  async (_, { dispatch, getState }) => {
    const loginState: AuthState = (getState() as any).loginInformation;
    const state: ITimesheetSlice = (getState() as any).timesheet;
    try {
      const editedTimesheet = state.timesheetList[0].childTimeSheets.filter(
        (_, index) => index === state.editTimesheetIndex
      )[0];

      const project = state.allProjects.filter(
        (project) => project.projectCode === editedTimesheet[0].projectCode
      )[0];

      const childTimeSheet = editedTimesheet.map((childsheet) => ({
        childTimeSheetId: childsheet.childTimeSheetId,
        employeeProjectId: childsheet.employeeProjectId,
        employeeId: loginState.user.employeeInfo.employeeId,
        projectId: childsheet.employeeProjectId,
        taskId: childsheet.taskId,
        date: childsheet.date,
        hours: childsheet.hours,
        pmId: project.managerId,
      }));
      const response = await sendRequestJSON(
        {
          timeSheetId: state.timesheetList[0].timeSheetId,
          childTimeSheet: childTimeSheet,
        },
        TimesheetAPIService.EDIT_TIMESHEET,
        "post"
      );
      if (response?.error) {
        dispatch(
          toastMessage({
            error: true,
            message: response.result.message,
          })
        );
      } else {
        dispatch(
          toastMessage({
            message: response.result.data?.message,
          })
        );
      }
      return response.result.data?.data;
    } catch (error) {
      if (error.response.data === "jwt expired") {
        dispatch(logout());
        return null;
      }
      dispatch(
        toastMessage({
          error: true,
          message: error.response.data,
        })
      );
      return null;
    }
  }
);

export const sumbitTimesheet = createAsyncThunk(
  "timesheet/sumbitTimesheet",
  async (payload: any, { dispatch }) => {
    try {
      const response = await sendRequestJSON(
        {
          timeSheetId: payload.timeSheetId,
          comment: payload.comment
        },
        // payload,
        TimesheetAPIService.SUBMIT_TIMESHEET,
        "put"
      );
      if (response?.error) {
        dispatch(
          toastMessage({
            error: true,
            message: response.result.message,
          })
        );
      } else {
        dispatch(
          toastMessage({
            message: response.result.data?.message,
          })
        );
      }
      return response.result.data?.data;
    } catch (error) {
      if (error.response.data === "jwt expired") {
        dispatch(logout());
        return null;
      }
      dispatch(
        toastMessage({
          error: true,
          message: error.response.data,
        })
      );
      return null;
    }
  }
);

export const unsumbitTimesheet = createAsyncThunk(
  "timesheet/unsumbitTimesheet",
  async (payload: any, { dispatch }) => {
    try {
      const response = await sendRequestJSON(
        {
          timeSheetId: payload.timeSheetId
        },
        TimesheetAPIService.UNSUBMIT_TIMESHEET,
        "put"
      );
      if (response?.error) {
        dispatch(
          toastMessage({
            error: true,
            message: response.result.message,
          })
        );
      } else {
        dispatch(
          toastMessage({
            message: response.result.data?.message,
          })
        );
      }
      return response.result.data?.data;
    } catch (error) {
      if (error.response.data === "jwt expired") {
        dispatch(logout());
        return null;
      }
      dispatch(
        toastMessage({
          error: true,
          message: error.response.data,
        })
      );
      return null;
    }
  }
);

export const exportReport = createAsyncThunk(
  "timesheet/export-report",
  async (payload: any, { dispatch }) => {
    try {
      const response = await sendRequestJSON(
        null,
        `${TimesheetAPIService.EXPORT_REPORT}?startDate=${payload.startDate}&endDate=${payload.endDate}`,
        "get",
        'blob'
      );
      if (response?.error) {
        dispatch(
          toastMessage({
            error: true,
            message: response.result.message,
          })
        );
      } else {
        // create file link in browser's memory
        console.log(response?.result)
        const href = URL.createObjectURL(response.result?.data);

        // create "a" HTML element with href to file & click
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', `report-${payload.startDate}-${payload.endDate}.xlsx`); //or any other extension
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
        dispatch(
          toastMessage({
            message: response.result.data?.message,
          })
        );
      }
      return response.result.data?.data;
    } catch (error) {
      if (error.response.data === "jwt expired") {
        dispatch(logout());
        return null;
      }
      dispatch(
        toastMessage({
          error: true,
          message: error.response.data,
        })
      );
      return null;
    }
  }
);
